import axios from 'axios'
import { signOut, refreshJwt } from '../Services/auth.service'
import { clearLocalStorageItemValue } from '../Services/local-storage.service'
import { setSessionExpired } from '../../Containers/ProtectedPageContainer/protectedPageSlice'
import { Dispatch } from '@reduxjs/toolkit'

axios.defaults.withCredentials = true

let isInterceptorRegistered = false
export const refreshJwtfunc = async () => {
  const response = await refreshJwt()
  return response.data
}

const axiosInterceptor = (currentUser: any, dispatch: Dispatch) => {
  if (isInterceptorRegistered) return
  isInterceptorRegistered = true
  axios.interceptors.request.use(
    (config) => {
      if (config && config.headers) {
        if (axios.defaults.headers.common.Authorization) {
          config.headers.Authorization = axios.defaults.headers.common.Authorization
        } else if (currentUser?.jwt_token) {
          config.headers.Authorization = `Bearer ${currentUser.jwt_token}`
        }
      }

      return config
    },
    (error) => {
      return Promise.reject(error)
    }
  )
  axios.interceptors.response.use(
    async (response) => {
      if (response.headers['initiate-refresh-flag'] === 'true') {
        const refreshJwtResponseData = await refreshJwtfunc()
        axios.defaults.headers.common[
          'Authorization'
        ] = `Bearer ${refreshJwtResponseData.user_data.jwt_token}`
      }
      return response
    },
    (error) => {
      if (error?.response?.data?.error_code === 401.4) {
        dispatch(setSessionExpired(true))
        return Promise.reject(error)
      }
      if (error?.response?.status === 401 && error?.response?.data?.error_code === 401.1) {
        signOut().then(() => {
          clearLocalStorageItemValue('currentUser')
          window.location.reload()
        })
      }
      return Promise.reject(error)
    }
  )
}

export default axiosInterceptor
