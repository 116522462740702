import { projectName } from '../Utils/utils'

export const en = {
  translation: {
    commonButtons: {
      edit: 'Edit',
      done: 'Done',
      cancel: 'Cancel',
      confirm: 'Confirm',
      yes: 'Yes',
      allow: 'Allow',
      deny: 'Deny',
      skipVideo: 'Skip Video',
      save: 'Save',
      close: 'Close',
      proceed: 'Proceed',
    },
    commonWords: {
      of: 'of',
    },
    menuDrawer: {
      title: 'Menu',
    },
    authPages: {
      logInContainer: {
        title: `Welcome to ${projectName}`,
      },
      error: {
        title: 'An Unexpected Error Occured',
        label: 'Unexpected error occurred',
        helpdeskInformation:
          'Please log in again. If the error persists, please reach out to the helpdesk with the technical description below: ',
        pageNotFoundMessage: 'Page not found',
        pageNotFoundLabel: 'Page not found!',
        errorMessage: 'Please retry again',
        link: '/lobby',
        closeButton: 'Return to Login',
        backToLogin: 'Back to log in',
      },
      logInForm: {
        button: {
          login: 'Log in',
          accentureLogIn: 'Log In via Accenture',
          linkedInLogIn: 'Log In with LinkedIn',
          pmiLogIn: 'Log In via PMI',
          rocheLogIn: 'Log In via Roche',
          facebookLogIn: 'Log In via Facebook',
          ktbLogin: 'Log In via KTB',
          googleLogIn: 'Sign in with Google',
          createAccount: 'Create Account',
          forgotPassword: 'Forgot password',
        },
        email: {
          placeholder: 'Username',
        },
        password: {
          placeholder: 'Password',
        },
        eventCode: {
          placeholder: 'Domain',
          errorMessage: '*Please enter a valid Domain',
        },
        consentRequirements: {
          personalData:
            'The protection of your personal data is very important to Accenture and any personal data you provide through this tool may be processed by Accenture. Accenture is committed to keeping your personal data secure and processing it in accordance with applicable privacy laws and our internal policies.',
          personalData2:
            'Personal data Accenture processes may be transferred or be accessible internationally throughout Accenture’s global business and between its entities and affiliates, and third parties.',
          personalData3: ' Before using this tool, Accenture invites you to carefully read its ',
          importantInformation:
            ', which includes important information on why and how Accenture is processing your personal data.',
          privacyStatement: 'privacy statement',
          message: 'I consent to the Accenture ',
          privacyPolicy: 'Privacy Policy',
          message2: '',
        },
        cookieConsent: {
          cookieMessage:
            'Accenture is using local storage technology (similar to functional cookies) on this site. This technology temporarily caches some limited information on your system ensuring a swift and smooth user experience. Any cookies and similar technology are used in accordance with Accenture’s ',
          cookiePolicy: 'cookies policy.',
        },
        localStorageConsent: {
          localStorageMessage:
            'I consent with the use of the local storage technology (similar to functional cookies).',
        },
        loginTitles: {
          loginViaEmail: 'Log In via email',
          login: 'Log In',
        },
      },
      oAuth2Login: {
        header: 'sign in was successful!',
        description: `Please enter Domain to continue to ${projectName}`,
        proceedMessage: 'Please press "Log in" to proceed!',
        eventCode: 'Domain',
      },
      TraverseOauthLogin: {
        header: 'Traverse login',
        description: 'Do you want to continue to Traverse ?',
        eventCode: 'Event Code',
        login: 'Authenticate to Traverse',
      },
      registerContainer: {
        title: `Registration for ${projectName}`,
      },
      registerForm: {
        button: {
          register: 'Register',
          login: 'Login',
        },
        firstName: {
          header: 'First name',
          placeholder: 'Enter your first name',
          errorMessage: 'Please enter your first name',
        },
        lastName: {
          header: 'Last name',
          placeholder: 'Enter your last name',
          errorMessage: 'Please enter your last name',
        },
        email: {
          header: 'Email',
          placeholder: 'Enter your email',
          errorMessage: '*Please enter your email',
        },
        password: {
          header: 'Password',
          placeholder: 'Enter your password',
          errorMessage: '*Please enter your password',
        },
        confirmPassword: {
          header: 'Confirm Password',
          placeholder: 'Enter your password again',
          notMatchMessage: '*Please make sure that your passwords match',
          errorMessage: '*Please enter your confirm password',
        },
        eventCode: {
          header: 'Domain',
          placeholder: 'Enter Domain',
          errorMessage: 'Please enter your Domain',
        },
      },
      successfullyRegistered: {
        title: 'Successfully registered',
        label: 'Email',
        placeholder: 'Enter your email',
        errorMessage: '*Please enter a registered email',
        sendButton: 'Send',
        backToLogin: 'Back to log in',
        successMessage: 'You will receive an email with instructions to recover your password.',
      },
      forgotPassword: {
        title: 'Forgot Password',
        label: 'Email',
        placeholder: 'Enter your email',
        errorMessage: '*Please enter a valid email-address',
        sendButton: 'Send',
        backToLogin: 'Back to log in',
        successMessage: 'You will receive an email with instructions to recover your password.',
      },
      resetPasswordContainer: {
        title: 'Set New Password',
        success: 'Your password has been reset successfully!',
      },
      resetPasswordForm: {
        button: {
          reset: 'Reset Password',
          backTologin: 'Back to log in',
        },
        newPassword: {
          header: 'New Password',
          placeholder: 'Enter your new password',
        },
        confirmPassword: {
          header: 'Confirm New Password',
          placeholder: 'Confirm your new password',
        },
      },
      changePasswordForm: {
        description:
          "Psst… if it's any help. Atrium requires that password be at least 8 characters long and does not require you to include numbers or uppercase letters.",
        currentPassword: 'Current Password',
        enterCurrentPasswordLabel: 'Enter your current password',
        oldPasswordErrorMessage: '*Please enter a valid password',
        newPassword: 'New password',
        enterNewPasswordLabel: 'Enter your new password',
        newPasswordErrorMessage: '*Please enter your new password',
        confirmNewPassword: 'Confirm new password',
        enterConfirmPasswordLabel: 'Confirm your new password',
        confirmPasswordErrorMessage: '*Please enter your confirm password',
        saveButton: 'Save Changes',
        successMessage: {
          title: 'Password Updated!',
          description:
            'Your password has been changed successfully. Use your new password to log in.',
        },
      },
      cookieConsent: {
        cookieMessage:
          'Accenture is using local storage technology (similar to functional cookies) on this site. This technology temporarily caches some limited information on your system ensuring a swift and smooth user experience. Any cookies and similar technology are used in accordance with Accenture’s ',
        cookiePolicy: 'cookies policy.',
      },
      localStorageConsent: {
        localStorageMessage:
          'I consent with the use of the local storage technology (similar to functional cookies).',
      },
      authFooter: {
        youCan: 'Or you can',
        dontHaveAccount: `Don't have an account?`,
        haveAccount: 'Already have an account?',
        termOfUse: 'Terms of use',
        privacyPolicy: 'privacy policy',
        helpInfo: 'If you need assistance please reach out to',
        and: 'and',
        lightFrontend: 'To join the accessible experience, please click',
        lightFrontendHere: 'here',
      },
      logout: {
        title: 'Log out',
      },
      loggedOutDialog: {
        title: 'Did you switch devices?',
        confirm: 'Log in again here',
        content: ' We noticed you logged in somewhere else. Thats why we log you off here.',
      },
      sessionExpired: {
        title: 'Session Expired',
        confirm: 'Log in again here',
        content: ' Your session expired. Please log in again.',
      },
      logoutDialog: {
        confirmText: 'Yes',
        cancelText: 'Cancel',
        noText: 'No',
        surveyText: 'Would you like to participate in a quick survey before you log out?',
        logoutText: `Do you want to exit ${projectName}?`,
        titleText: 'Logout',
      },
      confirmationDialogExample: {
        title: "Oh no! You're leaving...",
        content: `Are you sure you want to log out of ${projectName}?`,
        cancel: 'Just Kidding',
        confirm: 'Yes, Log Me Out',
      },
      accessCodeDialog: {
        header: 'Restricted Access',
        content:
          'The access to this space is restricted. Please provide a code to get access for your user account.',
        placeholder: 'Enter Access Code',
        cancel: 'Cancel',
        confirm: 'Proceed',
        errorMessage: 'Invalid access code. Please use a different code.',
      },
    },
    roomChatPage: {
      title: 'Room Chat',
      header: 'You are an early bird!',
      emptyConversation:
        'Until now nobody wrote something in the room chat. You can be the first one to say “Hi” to everybody.',
      subText: 'Just write your message below!',
    },
    userList: {
      title: 'User List',
      emptylist: 'Participant list is empty!',
      muteAll: 'Mute all',
    },
    recruiting: {
      candidatePage: {
        headerTitle: 'Candidate Info',
        loggedInInfo: {
          lastLogIn: 'Last time logged In',
          currently: 'Currently',
          recruitingStatus: 'Recruiting Status',
          changeStatus: 'Change Recruiting Status',
        },
        profile: {
          info: {
            title: 'Info',
            noInfo: 'No user info availble.',
            noStatus: 'Status is not availble.',
          },
          skills: {
            title: 'Skills',
            noSkills: 'The user did not enter any skills.',
          },
          interests: {
            title: 'Interests',
            noInterests: 'The user did not enter any interests.',
          },
          note: {
            title: 'Notes',
          },
          reportUser: 'ReportUser',
          newConversation: 'New Conversation',
          showAchievements: 'Show Achievements',
        },
        achievements: {
          title: 'Achievements',
          noBadges: 'This user has no badges.',
          candiAchievements: 'Candidates Achievements',
        },
        noUserSelected: 'Select a user for candidate details.',
      },
      userListPage: {
        headerTitle: 'Recruiting Dashboard',
        refresh: 'Refresh Page',
        search: {
          placeHolder: 'Search person in this event',
          noUser: 'No user found!',
        },
      },
      chatPage: {
        headerTitle: 'Chat',
        notificationTitle: 'Send Notification',
        noUserSelect: 'Select a user to continue chating.',
      },
    },
    chatPage: {
      title: 'Chat',
      chatList: {
        title: 'Chat List',
        searchBar: {
          placeholder: 'Search Person in your chat history',
        },
        newConversationButton: 'New Conversation',
        emptyConversation:
          'Seems like you have not started any conversations. Start one by clicking on a user or click on the “New Conversation”-Button below.',
      },
      chatNewConversation: {
        title: 'Chat List',
        newConversation: 'New Conversation',
        searchBar: {
          placeholder: 'Search Person in this event',
        },
        startConversation: 'Start Conversation',
        selectAPerson: 'Please select at least one person',
        cancel: 'Cancel',
        currently: `Currently `,
        peopleInEvent: ' people in this event',
        personInEvent: ' person in this event',
        noUsers: 'No people in this event',
        noSearchResult: 'No search results',
        userExists: 'User already added',
      },
      chatMessages: {
        timestamp: {
          conversationStart: 'Conversation Started',
          conversationExport:
            'Please note that all private chat messages are deleted after 10 days. Please use the private chat export function in the profile settings menu to save your conversations.',
        },
      },
      chatParticipantList: {
        roomTitle: 'Room Chat Info',
        groupTitle: 'Group Chat Info',
      },
      chatMessageInput: {
        placeholder: 'Type your Message here',
      },
    },
    businessCardsPage: {
      businessCardsList: {
        title: 'Business Cards List',
      },
      businessCard: {
        title: 'Business Card',
        skills: 'Skills',
        noSkills: 'The user did not enter any skills.',
        interests: 'Interests',
        noInterests: 'The user did not enter any interests.',
        info: 'Info',
        notes: 'Notes',
        reportUser: 'Report User',
        businessCardDelete: {
          title: 'Delete Business Card',
          message:
            'Are you sure you want to delete this Business Card? You will not access these information afterwards.',
        },
        reportuser: {
          title: 'Report user',
          message: 'Are you sure you want to report this user?',
        },
      },
      requestCard: {
        info: 'You do not have access to this full Business Card. Please Request Access first.',
        requestBusinessCard: 'Request Business Card',
        requestSent: 'Request Sent',
        message: {
          title: 'Can I collect your Business-Card?',
          description: 'The user can see your contact information.',
          allowed: 'Allowed',
          denied: 'Denied',
          requestSent: 'Request sent',
          approved: 'Approved',
        },
      },
      emptyPage: {
        info: 'Seems like you have not collected any Business Cards. You can request them directly in the chat.',
      },
      searchBarPlaceHolder: 'Search for a person',
    },
    tokenWalletPage: {
      menuTitle: 'Token Wallet',
      tokenWalletContainer: {
        header: 'Token Wallet',
        tokenID: 'Token ID: ',
        access: 'Access: ',
        code: 'Access Code: ',
        linkButton: 'Link',
        pageTitle: 'User entries?',
        description: 'List of tokens linked to your account in this event space',
      },
    },
    profileSettingsPage: {
      title: 'Profile Settings',
      changePicture: 'Change picture',
      changeAvatarPage: {
        profilePicture: 'Profile Picture',
        uploadPicture: 'Upload picture',
        takeAPhoto: 'Take a photo',
        removePicture: 'Remove picture',
        avatarDesign: 'Avatar Design',
        changeAppearance: 'Change Appearance',
      },
      changePicturePage: {
        profilePicture: 'Profile Picture',
        uploadPicture: 'Upload picture',
        takeAPhoto: 'Take a photo',
        removePicture: 'Remove picture',
        updateMessage: 'Your profile picture was successfully updated.',
        removeMessage: 'Your profile picture was successfully removed.',
      },
      informationPage: {
        title: 'Profile Information',
        info: 'This information will be displayed publicly so be careful what you share.',
        save: 'Save Changes',
        successInfo: 'User details are successfully updated.',
      },
      statusChange: {
        info: 'This information will be displayed publicly.',
        active: ' Appear as active',
        away: 'Appear as away',
        notDisturb: 'Do not disturb',
      },
      skillsPage: {
        title: 'Skills',
        subTitle1: "What's your super power?",
        subTitle2:
          'Help visitors to know you better by adding your strengths. Please add at least one.',
        searchSkills: 'Search & add skills',
        lableName: 'Enter your skill',
      },
      interestsPage: {
        title: 'Interests',
        subTitle1: 'What are you in to?',
        subTitle2:
          'Please add at least one interest by searching or click on one of the suggestions below.',
        addInterest: 'Search & add interests',
        add: 'Add',
        lableName: 'Enter your interest',
      },
      menu: {
        changeStatus: 'Change Status',
        changePicture: 'Change Picture',
        changeAppearance: 'Change Appearance',
        profileInformation: 'Profile Information',
        skills: 'Skills',
        interests: 'Interests',
        changePassword: 'Change Password',
        exportPrivateChats: 'Export private chats',
        deleteAccount: 'Delete my account',
      },
      deleteUser: {
        dialog: {
          title: 'Delete Account',
          message:
            'Are you sure you want to delete your account? If you delete your account, you will permanently lose your profile.',
          cancel: 'Cancel',
          confirm: 'Yes',
        },
        sucess: {
          title: 'Your Account is now deleted!',
          backToLogin: 'Back to Log In',
        },
      },
    },
    passport: {
      title: 'Achievements',
      achievementsList: 'Achievements List',
      emptyBadgePage: 'No achievements.',
      rewardPage: {
        pageTitle: 'What is the challenge?',
        pageDescription: 'Complete the challenges by exploring the platform.',
        rewardClaimButton: 'Complete Your Achievements',
        rewardClaimedButton: 'Achievements Completed',
        participationMessage: 'Thank you for participating.',
      },
      redeemCoins: {
        title: 'Redeem Coins',
        myCoins: 'My Coins:',
        achievementList: 'Achievement List',
        pageTitle: 'Redeem your coins for goodies!',
        pageDescription: 'Redeem your coins by exploring the list of items below.',
        shippingDeatials: 'Enter Shipping Details',
        goodies: 'Goodies:',
        buy: 'Buy',
        emptyList: 'Goodies list is empty.',
      },
      purchaseDialog: {
        title: 'Buy Item',
        descriptions: 'Do you want to use {{goodiesPrice}} coins and buy {{selectedGoodieItem}}?',
      },
      confirmationDialog: {
        successTitle: 'Thank you!',
        successMessage: 'You have bought {{selectedGoodieItem}} for {{goodiesPrice}} coins.',
        errorTitle: 'Error',
        errorMessage:
          'Insufficient balance to buy {{selectedGoodieItem}}. Please collect achievements to get more coins.',
      },
    },
    helpMenu: {
      title: 'Help',
      userManual: {
        title: 'User Manual',
        subTitle1: 'More informations?',
        description: 'Click on the button below to see your user manual',
        userMenuButton: 'Access User Manual',
      },
      helpDesk: {
        title: 'Contact Helpdesk',
        pageTitle: 'What is the problem?',
        pageDescription: 'Insert the question in the field below and find out the answer',
        phonePlaceholder: 'Insert your phone number',
        emailPlaceholder: 'Insert your email',
        textPlaceholder: 'Write your question here',
        errorMessagePhone: '*Please enter a valid phone number',
        errorMessageEmail: '*Please enter a valid email adress',
        button: 'Conatct Helpdesk',
        textAreaError: '*Please explain your problems or question to us in more detail.',
      },
      faq: {
        menuTitle: 'FAQ',
        title: 'Frequently Asked Questions',
        button: 'Submit',
        success: 'Your question has been successfully submitted!',
      },
      privacyPolicy: {
        title: 'Accenture Privacy Policy',
        subTitle1: 'More informations?',
        description: 'Click on the button below to see Accenture privacy policy.',
        privacyPolicyButton: 'Access Privacy Policy',
      },
      cookiePolicy: {
        title: 'Accenture Cookie Policy',
        description: 'Click on the button below to see the cookie policy',
        userMenuButton: 'Access Cookie Policy',
      },
    },
    settings: {
      title: 'Settings',
      turnOffNewsfeedNotifications: 'Turn off Newsfeed Notifications',
      turnOnNewsfeedNotifications: 'Turn on Newsfeed Notifications',
      turnOffBackgroundMusic: 'Turn off Background Music',
      turnOnBackgroundMusic: 'Turn on Background Music',
      changeLanguage: 'Change Language',
      changeLanguagePrompt: 'Change the language of the whole application.',
      turnOffMiniMap: 'Turn off Mini-Map',
      turnOnMiniMap: 'Turn on Mini-Map',
      turnOnThirdPersonView: 'Third Person Perspective (TPP)',
      turnOffThirdPersonView: 'First Person Perspective (FPP)',
    },
    eventNotification: {
      title: 'Event Notification',
      InputForm: {
        title: 'What is the notification?',
        titlePlaceholder: 'Title',
        description: 'Insert the text in the field below to create your notification',
        textAreaPlaceholder: 'Write notification here',
        maxLimitInfo: '500 (Maximum characters)',
        linkInfo:
          'To include external link in a message, please use the format "[text | URL ]" Example: [link | www.google.com]',
      },
      audienceOptions: {
        title: 'Set Audience',
      },
      recipientOption: {
        title: 'Set Recipient',
      },
      error: {
        title: 'Whoops! Something went wrong',
        label: 'Unexpected error occurred',
        pageNotFoundMessage: 'Page not found!',
        pageNotFoundLabel: 'Please try later',
        errorMessage: 'Please retry again',
        link: '/lobby',
        closeButton: 'go to Homepage',
        backToLogin: 'Back to log in',
      },
      notificationType: {
        title: 'Notification Type',
      },
      messageDurationType: {
        title: 'Display Duration',
      },
      successMessage: {
        title: 'Successfully submitted!',
        description: 'Your notification has been submitted succesfully.',
      },
      failureMessage: {
        title: 'Oops, submission failed!',
        description: 'Something went wrong, please try again.',
      },
      userAchievement: {
        badgeCompleted: 'You have completed this badge',
        additionalProgress: 'You have made additional progress towards a badge',
        madeProgress: 'You have made progress towards a badge',
        alreadyCollected: 'This badge is already collected',
        foundBadge: 'Congratulations, you found a badge',
      },
      submitButton: 'Submit',
      roomAccessGranted: {
        title: 'Access granted',
        description: 'You now have access to {{rooms, list}}.',
      },
      roomAccessRevoked: {
        title: 'Access revoked',
        description:
          'Your access to this room was revoked. You will be redirected to the lobby in 5 seconds.',
      },
    },
    roomInstancesDialog: {
      title: 'Choose room instance',
      text: 'Please select the room instance that you want to join!',
      errorMessage: 'Could not retrieve room instances, try to reload the page.',
    },
    eventInfo: {
      information: 'Information',
      eventClosed: 'The event is currently closed. We look forward to seeing you soon.',
      eventWillOpen: 'Event will be open ',
    },
    incomingCallDialog: {
      incomingCallTitle: 'Calling you...',
    },
    restrictedRoomMessage: {
      title: 'Access Denied',
      description: 'You are not allowed to access this room.',
    },
    login: {
      form: {
        labels: {
          domain: 'Domain',
          email: 'Email',
          password: 'Password',
        },
        placeholders: {
          email: 'name.surname@mail.com',
        },
        consent: {
          privacy: 'I consent to the Accenture Privacy Policy',
          cookies:
            'I consent with the use of local storage technology (similar to functional cookies)',
        },
        errors: {
          domain: {
            title: 'Wrong code',
            message: 'Please enter a valid domain name',
          },
          terms: {
            title: 'Please accept the terms',
            message: 'You must accept the terms to be able to login',
          },
          login: {
            title: 'Error checking Reality Code',
            message: 'An error append durring the login process',
          },
        },
        buttons: {
          login: 'Login',
          adminDashboard: 'Access Dashboard',
          showPassword: 'Show password',
          hidePassword: 'Hide password',
        },
        dividers: {
          socialLogin: 'Or login with',
        },
        socialButtons: {
          google: 'Google',
          accenture: 'Accenture',
          linkedin: 'LinkedIn',
          facebook: 'Facebook',
        },
        adminSwitch: {
          toAdmin: 'Login as administrator',
          toVisitor: 'Login as visitor',
        },
      },
    },
  },
}
