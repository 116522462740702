import React from 'react'

export const Icons = {
  Eye: () => (
    <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M8.0013 3C4.66797 3 1.8213 5.07333 0.667969 8C1.8213 10.9267 4.66797 13 8.0013 13C11.3346 13 14.1813 10.9267 15.3346 8C14.1813 5.07333 11.3346 3 8.0013 3ZM8.0013 11.3333C6.1613 11.3333 4.66797 9.84 4.66797 8C4.66797 6.16 6.1613 4.66667 8.0013 4.66667C9.8413 4.66667 11.3346 6.16 11.3346 8C11.3346 9.84 9.8413 11.3333 8.0013 11.3333ZM8.0013 6C6.89464 6 6.0013 6.89333 6.0013 8C6.0013 9.10667 6.89464 10 8.0013 10C9.10797 10 10.0013 9.10667 10.0013 8C10.0013 6.89333 9.10797 6 8.0013 6Z'
        fill='#fff'
      />
    </svg>
  ),
  EyeCrossed: () => (
    <svg width='16' height='10' viewBox='0 0 16 10' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M8.0013 0C4.66797 0 1.8213 2.07333 0.667969 5C1.8213 7.92667 4.66797 10 8.0013 10C11.3346 10 14.1813 7.92667 15.3346 5C14.1813 2.07333 11.3346 0 8.0013 0ZM8.0013 8.33333C6.1613 8.33333 4.66797 6.84 4.66797 5C4.66797 3.16 6.1613 1.66667 8.0013 1.66667C9.8413 1.66667 11.3346 3.16 11.3346 5C11.3346 6.84 9.8413 8.33333 8.0013 8.33333ZM8.0013 3C6.89464 3 6.0013 3.89333 6.0013 5C6.0013 6.10667 6.89464 7 8.0013 7C9.10797 7 10.0013 6.10667 10.0013 5C10.0013 3.89333 9.10797 3 8.0013 3Z'
        fill='#fff'
      />
    </svg>
  ),
  LinkedIn: () => (
    <svg width='35' height='35' viewBox='0 0 35 35' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M35 17.5C35 7.83502 27.165 0 17.5 0C7.83502 0 0 7.83502 0 17.5C0 27.165 7.83502 35 17.5 35C27.165 35 35 27.165 35 17.5Z'
        fill='#1275B1'
      />
      <path
        d='M13.2732 9.61519C13.2732 10.7834 12.2606 11.7304 11.0116 11.7304C9.76255 11.7304 8.75 10.7834 8.75 9.61519C8.75 8.447 9.76255 7.5 11.0116 7.5C12.2606 7.5 13.2732 8.447 13.2732 9.61519Z'
        fill='white'
      />
      <path d='M9.05859 13.2852H12.9246V25H9.05859V13.2852Z' fill='white' />
      <path
        d='M19.1472 13.2858H15.2812V25.0007H19.1472C19.1472 25.0007 19.1472 21.3127 19.1472 19.0068C19.1472 17.6227 19.6199 16.2325 21.5055 16.2325C23.6365 16.2325 23.6237 18.0438 23.6137 19.447C23.6007 21.2812 23.6318 23.153 23.6318 25.0007H27.4978V18.8178C27.465 14.8699 26.4363 13.0508 23.0519 13.0508C21.042 13.0508 19.7961 13.9633 19.1472 14.7888V13.2858Z'
        fill='white'
      />
    </svg>
  ),
  Facebook: () => (
    <svg width='35' height='35' viewBox='0 0 35 35' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M17.5 35C27.165 35 35 27.165 35 17.5C35 7.83502 27.165 0 17.5 0C7.83502 0 0 7.83502 0 17.5C0 27.165 7.83502 35 17.5 35Z'
        fill='url(#paint0_linear_425_16458)'
      />
      <path
        d='M24.0171 22.852L24.7945 17.9126H19.9315V14.7088C19.9315 13.3571 20.6096 12.0389 22.7878 12.0389H25V7.83374C25 7.83374 22.9931 7.5 21.0754 7.5C17.0685 7.5 14.4521 9.86618 14.4521 14.148V17.9126H10V22.852H14.4521V34.7931C15.3459 34.93 16.2602 35 17.1917 35C18.1233 35 19.0378 34.93 19.9315 34.7931V22.852H24.0171Z'
        fill='white'
      />
      <defs>
        <linearGradient
          id='paint0_linear_425_16458'
          x1='17.5'
          y1='0'
          x2='17.5'
          y2='34.8963'
          gradientUnits='userSpaceOnUse'
        >
          <stop stop-color='#18ACFE' />
          <stop offset='1' stop-color='#0163E0' />
        </linearGradient>
      </defs>
    </svg>
  ),
  Google: () => (
    <svg width='35' height='35' viewBox='0 0 35 35' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M7.52947 17.5021C7.52947 16.3907 7.71407 15.3251 8.04354 14.3257L2.27669 9.92188C1.15276 12.2039 0.519531 14.7752 0.519531 17.5021C0.519531 20.2267 1.15198 22.7964 2.27435 25.0769L8.03809 20.6645C7.71173 19.6698 7.52947 18.6081 7.52947 17.5021Z'
        fill='#FBBC05'
      />
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M17.6556 7.39062C20.0701 7.39062 22.251 8.24618 23.9646 9.64618L28.9494 4.6684C25.9118 2.02396 22.0173 0.390625 17.6556 0.390625C10.884 0.390625 5.06419 4.26318 2.27734 9.92151L8.04419 14.3253C9.37296 10.2917 13.1607 7.39062 17.6556 7.39062Z'
        fill='#EB4335'
      />
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M17.6556 27.6144C13.1607 27.6144 9.37296 24.7132 8.04419 20.6797L2.27734 25.0827C5.06419 30.7418 10.884 34.6144 17.6556 34.6144C21.8351 34.6144 25.8253 33.1304 28.8201 30.3498L23.3461 26.1179C21.8016 27.0909 19.8567 27.6144 17.6556 27.6144Z'
        fill='#34A853'
      />
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M34.0128 17.5017C34.0128 16.4906 33.857 15.4017 33.6233 14.3906H17.6562V21.0017H26.8471C26.3875 23.2557 25.1366 24.9886 23.3468 26.1164L28.8208 30.3483C31.9667 27.4285 34.0128 23.0792 34.0128 17.5017Z'
        fill='#4285F4'
      />
    </svg>
  ),
  Accenture: () => (
    <svg width='34' height='35' viewBox='0 0 34 35' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M0 24.7059L19.3529 17.5L0 10.0882V0L33.1471 13.3824V21.6176L0 35V24.7059Z'
        fill='#A100FF'
      />
    </svg>
  ),
  Close: () => (
    <svg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M1 1L13 12.9999' stroke='#E0E0E0' stroke-width='2' stroke-linecap='round' />
      <path d='M13 1L1 12.9999' stroke='#E0E0E0' stroke-width='2' stroke-linecap='round' />
    </svg>
  ),
  Spinner: () => (
    <svg fill='#fff' viewBox='0 0 512 512' xmlns='http://www.w3.org/2000/svg'>
      <g id='SVGRepo_bgCarrier' stroke-width='0'></g>
      <g id='SVGRepo_tracerCarrier' stroke-linecap='round' stroke-linejoin='round'></g>
      <g id='SVGRepo_iconCarrier'>
        <path d='M304 48c0 26.51-21.49 48-48 48s-48-21.49-48-48 21.49-48 48-48 48 21.49 48 48zm-48 368c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48-21.49-48-48-48zm208-208c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48-21.49-48-48-48zM96 256c0-26.51-21.49-48-48-48S0 229.49 0 256s21.49 48 48 48 48-21.49 48-48zm12.922 99.078c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48c0-26.509-21.491-48-48-48zm294.156 0c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48c0-26.509-21.49-48-48-48zM108.922 60.922c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48-21.491-48-48-48z'></path>
      </g>
    </svg>
  ),
  CheckCircle: () => (
    <svg viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g id='SVGRepo_bgCarrier' stroke-width='0'></g>
      <g id='SVGRepo_tracerCarrier' stroke-linecap='round' stroke-linejoin='round'></g>
      <g id='SVGRepo_iconCarrier'>
        {' '}
        <path
          d='M8 12.3333L10.4615 15L16 9M21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12Z'
          stroke='#fff'
          stroke-width='2'
          stroke-linecap='round'
          stroke-linejoin='round'
        ></path>{' '}
      </g>
    </svg>
  ),
  XCircle: () => (
    <svg viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g id='SVGRepo_bgCarrier' stroke-width='0'></g>
      <g id='SVGRepo_tracerCarrier' stroke-linecap='round' stroke-linejoin='round'></g>
      <g id='SVGRepo_iconCarrier'>
        {' '}
        <path
          d='M9 9L15 15M15 9L9 15M21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12Z'
          stroke='#fff'
          stroke-width='2'
          stroke-linecap='round'
          stroke-linejoin='round'
        ></path>{' '}
      </g>
    </svg>
  ),
}
