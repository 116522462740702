import React, { FC, useEffect, useState } from 'react'
import { Redirect, Route, RouteProps, useLocation } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '../../../Reducers/hooks'
import { updateCurrentUser } from '../../../Reducers/authSlice'
import { fetchPreferences } from '../../../Reducers/preferencesSlice'
import { axiosInterceptor, localStorageService, User } from '../../../Services/services-index'

function useQuery() {
  return new URLSearchParams(decodeURI(useLocation().search))
}

const ProtectedRouter: FC<RouteProps> = ({ children, ...rest }) => {
  const currentUser = useAppSelector((state) => state.auth.currentUser)
  const sessionExpiredStatus = useAppSelector((state) => state.protectedPage.isSessionExpired)
  const dispatch = useAppDispatch()
  const [hasLoggedIn, setHasLoggedIn] = useState<boolean | null>(null)
  const [hasAuth, setHasAuth] = useState<boolean>(false)
  const preferences = useAppSelector((state) => state.preferences.preferences)
  let currentUserParsed: User
  const query = useQuery()

  const client_id = query.get('client_id')

  useEffect(() => {
    if (client_id === 'traverse') {
      setHasAuth(true)
      const redirect_url = query.get('redirect_url')
      const state = query.get('state')
      localStorageService.setLocalStorageItemValue('traverseRedirect', JSON.stringify(true))
      localStorageService.setLocalStorageItemValue('traverseClientID', client_id)
      localStorageService.setLocalStorageItemValue('traverseRedirectURL', redirect_url)
      localStorageService.setLocalStorageItemValue('traverseState', state)
    }
    if (sessionExpiredStatus) {
      return
    }
    if (currentUser) {
      if (hasLoggedIn && !preferences) {
        dispatch(fetchPreferences({ type: 'specific' }))
        return
      } else {
        axiosInterceptor(currentUser, dispatch)
        setHasLoggedIn(true)
      }
    } else {
      const user: any = localStorageService.getLocalStorageItemValue('currentUser')
      if (user) {
        currentUserParsed = JSON.parse(user)
        axiosInterceptor(currentUserParsed, dispatch)
        setHasLoggedIn(true)
      } else {
        setHasLoggedIn(false)
      }
      dispatch(updateCurrentUser(currentUserParsed))
    }
  }, [currentUser, hasLoggedIn, sessionExpiredStatus])

  if (hasLoggedIn === null) {
    return null
  }

  return (
    <Route
      {...rest}
      render={({ location }: { location: any }) =>
        hasLoggedIn ? (
          location.pathname === '/' ? (
            <Redirect to={{ pathname: '/lobby', state: { from: location } }} />
          ) : location.pathname === '/auth' && client_id === 'traverse' ? (
            <Redirect to={{ pathname: '/auth', state: { from: location } }} />
          ) : (
            children
          )
        ) : (
          <Redirect
            to={{
              pathname: '/auth/login',
              state: { from: location, hasAuth: hasAuth },
            }}
          />
        )
      }
    />
  )
}

export default ProtectedRouter
