import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  isSessionExpired: false,
}
const protectedPageSlice = createSlice({
  name: 'ProtectedPages',
  initialState,
  reducers: {
    setSessionExpired(state, action) {
      state.isSessionExpired = action.payload
    },
  },
})

export const { setSessionExpired } = protectedPageSlice.actions
export default protectedPageSlice.reducer
