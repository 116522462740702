import React, { FC, useEffect, useState } from 'react'
import { LoginHeader } from './LoginHeader'
import { LoginForm } from './LoginForm'
import { Disclaimer } from './Disclaimer'
import { contentService } from '../../../Services/services-index'

export const LoginPage: FC = () => {
  const [backgroundImage, setBackgroundImage] = useState('')
  const [_, setIsLoading] = useState(false)

  const [isFirstLoad, setIsFirstLoad] = useState(true)

  useEffect(() => {
    localStorage.clear()
    sessionStorage.clear()
  }, [])

  useEffect(() => {
    contentService.getPosterFile(null).then((res) => {
      const image = URL.createObjectURL(res.data)
      setBackgroundImage(image)
      setIsLoading(false)
      setIsFirstLoad(false) // Marquer que le premier chargement est terminé
    })
  }, [])

  useEffect(() => {
    contentService.getPosterFile(null).then((res) => {
      const image = URL.createObjectURL(res.data)
      setBackgroundImage(image)
      setIsLoading(false)
    })
  }, [])

  const onNewBackgroundImage = (domain: string) => {
    setIsLoading(true)
    contentService.getPosterFile(domain).then((res) => {
      const image = URL.createObjectURL(res.data)
      setBackgroundImage(image ?? '')
      setIsLoading(false)
    })
  }

  const containerStyle: React.CSSProperties = {
    position: 'fixed' as const,
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundImage: `url(${backgroundImage})`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    zIndex: -1,
    opacity: isFirstLoad ? 0 : 1,
    transition: isFirstLoad
      ? 'opacity 0.5s ease-in-out'
      : 'background-image 0.5s ease-in-out, opacity 0.5s ease-in-out',
  }

  const containerStyleBackground: React.CSSProperties = {
    position: 'fixed' as const,
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'black',
    zIndex: -100,
  }

  const modalStyle: React.CSSProperties = {
    position: 'fixed' as const,
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 'calc(100% - 40px)', // Ensures 20px gap on each side
    maxWidth: '400px',
    maxHeight: '90vh', // Add maximum height
    backgroundColor: '#1a1a1a',
    color: '#fff',
    borderRadius: '12px',
    padding: '24px',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
    fontFamily: 'Graphik',
    boxSizing: 'border-box',
    overflowY: 'auto', // Enable vertical scrolling
    // Optional: Improve scroll appearance
    scrollbarWidth: 'thin',
    scrollbarColor: '#666 #1a1a1a',
    ['&::-webkit-scrollbar' as any]: {
      width: '8px',
    },
    ['&::-webkit-scrollbar-track' as any]: {
      background: '#1a1a1a',
    },
    ['&::-webkit-scrollbar-thumb' as any]: {
      background: '#666',
      borderRadius: '4px',
    },
  }
  return (
    <div>
      <div style={containerStyleBackground} />
      <div style={containerStyle} />
      <div style={modalStyle}>
        <LoginHeader />
        <LoginForm onNewBackgroundImage={onNewBackgroundImage} />
      </div>
      <Disclaimer />
    </div>
  )
}
